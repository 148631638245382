import React, { useState, useEffect } from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import Footer from '../../components/footer/footer.jsx';
import Header from '../../components/header/header.jsx';
import Sidemenu from '../../components/sidemenu/sidemenu.jsx';
import Banner from '../../components/banner/banner.jsx';
import { getClientes } from '../../api/service.ts';
import './Clientes.css';
import { jwtDecode } from 'jwt-decode';
import { format } from 'date-fns';
import { Dialog } from 'primereact/dialog';

const Clientes = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [rows] = useState(10);
  const [globalFilter, setGlobalFilter] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const getDisplayValue = (value) => value || 'Não informado';

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        setIsLoggedIn(true);
        const decodedToken = jwtDecode(token);
        const adminStatus = decodedToken.isAdmin;
        setIsAdmin(adminStatus);

        if (adminStatus) {
          fetchClientes(token);
        }
      } catch (error) {
        alert("Faça login novamente:", error);
      }
    }
  }, []);

  const fetchClientes = async (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const clientesData = await getClientes(decodedToken);
      setClientes(clientesData);
    } catch (error) {
      alert("Faça login novamente:", error);
    }
  };

  const formatarData = (data) => {
    return format(new Date(data), 'dd/MM/yyyy');
  };

  const showAddressModal = (rowData) => {
    setSelectedClient(rowData);
  };

  const header = (
    <div className="flex justify-content-between align-items-center">
      <h3 className="m-3">Pedidos de Clientes</h3>
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <InputText
          type="text"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar"
        />
      </span>
    </div>
  );

  return (
    <>
      <Banner />
      <Header />
      <Sidemenu />
      <div className="container-pedidos">
        {isLoggedIn && isAdmin ? (
          <div className="card">
            <DataTable
              value={clientes}
              showGridlines
              tableStyle={{ minWidth: '50rem' }}
              paginator={clientes.length > 10}
              rows={rows}
              rowsPerPageOptions={[5, 10, 20]}
              sortMode="multiple"
              globalFilter={globalFilter}
              header={header}
              emptyMessage="Nenhum pedido encontrado."
            >
              <Column field="id" header="ID" />
              <Column
                header="Cliente"
                body={(rowData) => (
                  <button onClick={() => showAddressModal(rowData)} className="p-button p-button-info">
                    Dados Cliente
                  </button>
                )}
              />
              <Column field="quantidade" header="Quantidade" />
              <Column field="valor_pago" header="Preço" />
              <Column field="metodo_pagamento" header="Forma Pagamento" />
              <Column field="status" header="Status Pagamento" />
              <Column field="tipoFrete" header="Tipo Frete" />
              <Column field="data_pedido" header="Data Pedido" body={(rowData) => formatarData(rowData.data_pedido)} />
            </DataTable>

            <Dialog
              header="Detalhes do Cliente"
              visible={!!selectedClient}
              onHide={() => setSelectedClient(null)}
              style={{ width: '400px' }}
              className="show-dialog"
              breakpoints={{ '960px': '75vw', '641px': '100vw' }}
              draggable={false}
            >
              {selectedClient && (
                <div>
                  <p><strong>Nome:</strong> {getDisplayValue(selectedClient.nomeCliente)}</p>
                  <p><strong>Email:</strong> {getDisplayValue(selectedClient.email)}</p>
                  <p><strong>CPF:</strong> {getDisplayValue(selectedClient.cpf)}</p>
                  <p><strong>Telefone:</strong> {getDisplayValue(selectedClient.telefone)}</p>
                  <p><strong>Data de Nascimento:</strong> {selectedClient.dataNascimento ? formatarData(selectedClient.dataNascimento) : 'Não informado'}</p>
                  <p><strong>CEP:</strong> {getDisplayValue(selectedClient.cep)}</p>
                  <p><strong>Rua:</strong> {getDisplayValue(selectedClient.rua)}</p>
                  <p><strong>Número:</strong> {getDisplayValue(selectedClient.numero)}</p>
                  <p><strong>Complemento:</strong> {getDisplayValue(selectedClient.complemento)}</p>
                  <p><strong>Bairro:</strong> {getDisplayValue(selectedClient.bairro)}</p>
                  <p><strong>Cidade:</strong> {getDisplayValue(selectedClient.cidade)}</p>
                  <p><strong>Estado:</strong> {getDisplayValue(selectedClient.estado)}</p>
                </div>
              )}
            </Dialog>

          </div>
        ) : (
          <h2 className='textAcesso'>Faça o login como admin para visualizar os pedidos de clientes.</h2>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Clientes;