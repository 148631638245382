import React from 'react';
import './index.css';
import produto from '../../img/imgValor.jpeg';
import btnCom from '../../img/btn-removebg-preview.png';
import { Link } from 'react-router-dom';
import Header from '../../components/header/header';
import Sidemenu from '../../components/sidemenu/sidemenu';
import Footer from '../../components/footer/footer';
import BtnComprar from '../../components/buttonCompre/btnComprar';
import Banner from '../../components/banner/banner';
import Carrossel from '../../components/carrossel/carrossel';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Home() {
  const location = useLocation();

  useEffect(() => {
    // Verifica se o hash está presente na URL
    if (location.hash === '#sobre-nos') {
      const sobreNosSection = document.getElementById('sobre-nos');
      if (sobreNosSection) {
        sobreNosSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="container">
      <Banner />
      <Sidemenu />
      <Header />
      <div className="content-home">
        <div className="blocoProduto">
          <img alt="produto" src={produto} className="produto" />
        </div>

        <BtnComprar />

        <Link to="/compras">
          <img alt="produto" src={btnCom} className="btnProd" />

        </Link>
        <Carrossel />

        <section className="sobre-nos" id="sobre-nos">
          <div className="titulo-sobre">
            <h3>Sobre Nós</h3>
          </div>

          <div className='texto-sobre'>
            <p>Na VittaLux, somos apaixonados por revelar a beleza que reside em cada pessoa através de cuidados dermocosméticos de excelência. Nossa missão é proporcionar soluções inovadoras e eficazes, unindo ciência e natureza para transformar o cuidado com a pele em uma experiência única e revitalizante.</p>
            <p>Cada produto da VittaLux é desenvolvido com composições de alta qualidade, combinando tecnologia de ponta e conhecimento dermatológico para atender às necessidades específicas de cada tipo de pele. </p> 
            <p> Acreditamos que a verdadeira beleza vem do cuidado. Por isso, estamos dedicados a criar produtos que não apenas realçam a sua aparência, mas também cuidam da sua pele de forma profunda e gentil.</p>
            <h4>VittaLux, onde a Beleza encontra cuidado.</h4>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
