import React, { useState } from 'react';
import './login.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Sidemenu from '../../components/sidemenu/sidemenu';
import Banner from '../../components/banner/banner';  

function Login() {
  const URL_SERVER = process.env.REACT_APP_URL_SERVER;
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${URL_SERVER}/login/login`, {
        email,
        senha,
      });

      const token = response.data.token;
      localStorage.setItem('token', token);

      navigate('/');

    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Erro ao tentar fazer login.');
      }
      console.error(err);
    }
  };

  return (
    <div className="container-login">
      {/* Cabeçalho */}
      <Banner />
      <Sidemenu />
      <Header />

      {/* Conteúdo */}
      <div className="content-login">
        <div className="form-container-login">
          <h2>Login</h2>
          {error && <p className="error-message">{error}</p>}
          <form id="formLogin" onSubmit={handleLogin}>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              placeholder='Digite seu email'
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label htmlFor="password">Senha:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={senha}
              placeholder='Digite sua senha'
              onChange={(e) => setSenha(e.target.value)}
              required
            />

            <button id="btnLogar-login" type="submit">Entrar</button>

            <Link to='/cadastro' id='btn-cadastrar'>
              <button id="btnCadastrar-login">Cadastrar-se</button>
            </Link>

          </form>
        </div>
      </div>

      {/* Rodapé */}
      <Footer />
    </div>
  );
}

export default Login;
