import React from 'react';
import './Sac.css';
import { FaInstagram } from 'react-icons/fa';
import { MdMailOutline } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { FaLock, FaTruck, FaHeadset } from 'react-icons/fa';
import Footer from '../../components/footer/footer';
import BtnComprar from '../../components/buttonCompre/btnComprar';
import Header from '../../components/header/header';
import Sidemenu from '../../components/sidemenu/sidemenu';
import Banner from '../../components/banner/banner';

function Home() {
  return (
    <>
      <div className="container">
        <Banner />
        <Sidemenu />
        <Header />

        <div className="content-home">
          <BtnComprar />

          <section className="section-sac">  
            <div className="title-sac">
              <h3 id="text-sac">Qualquer dúvida com nossos produtos, basta entrar em contato via SAC:</h3>
            </div>

            <div className="sac-contact">
              <a href="https://www.instagram.com/vittalux.dermocosmeticos/" target="_blank" rel="noopener noreferrer">
                <p id='instaSac'> <FaInstagram className="footer-icon" /> Instagram</p>
              </a>
              <a href="mailto:comercial@vittalux.com.br" target="_blank" rel="noopener noreferrer">
                <p id='mailSac'> <MdMailOutline className="footer-icon" /> comercial@vittalux.com.br</p>
              </a>
              <a href="tel:+5515991713753" target="_blank" rel="noopener noreferrer">
                <p><FaPhone className="footer-icon" /> +55 15 99171-3753</p>
              </a>
            </div>

            <div className="sac-info">
              <div className="sac-info-item">
                <FaLock className="sac-icon" />
                <div>
                  <h4>COMPRA SEGURA</h4>
                  <p>Pagamentos processados diretamente pelo Mercado Pago.</p>
                </div>
              </div>
              <div className="sac-info-item">
                <FaTruck className="sac-icon" />
                <div>
                  <h4>ENTREGA EXPRESSA</h4>
                  <p>Envios são feitos por PAC ou Sedex dos Correios.</p>
                </div>
              </div>
              <div className="sac-info-item">
                <FaHeadset className="sac-icon" />
                <div>
                  <h4>ATENDIMENTO PERSONALIZADO</h4>
                  <p>Realiazdo via, WhatsApp, e-mail ou redes sociais.</p>
                </div>
              </div>
            </div>         
          </section>    
        </div>
      </div>
      <div className="footerSac">
        <Footer />
      </div>
    </>
  );
}

export default Home;
