import axios from "axios";
const URL_SERVER = process.env.REACT_APP_URL_SERVER; 

export async function postPix(body: any) {
  try {
    const response = await axios.post(`${URL_SERVER}/pix`, body);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function postCard(body: any) {
  try {
    const response = await axios.post(`${URL_SERVER}/process_payment`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });    
    return response;
  } catch (error) {
    console.error("Erro ao processar o pagamento com cartão:", error);
    throw error;
  }
}

export async function consultarFrete(body: any) {
  try {
    const response = await axios.post(`${URL_SERVER}/calcular_frete`, body);
    return response;
  } catch (error) {
    throw error;
  }
} 

export async function getPedidos(body: any) {
  try {
    const response = await axios.post(`${URL_SERVER}/pedidos`, body);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar os pedidos:", error);
    throw error;
  }
}

export async function getClientes(body: any) {
  try {
    const response = await axios.post(`${URL_SERVER}/clientes`, body);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar os pedidos:", error);
    throw error;
  }
}


