import React, { useState, useEffect } from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';  
import 'primereact/resources/primereact.min.css';                  
import 'primeicons/primeicons.css';                                
import 'primeflex/primeflex.css';           
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext'; 
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Sidemenu from '../../components/sidemenu/sidemenu';
import { getPedidos } from '../../api/service.ts';
import {jwtDecode} from 'jwt-decode';
import './Pedidos.css';
import Banner from '../../components/banner/banner.jsx';

const Pedidos = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [rows] = useState(10);  
  const [globalFilter, setGlobalFilter] = useState('');  

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        setIsLoggedIn(true);
        fetchPedidos(token);
      } catch (error) {
        alert("Faça login novamente:", error);
      }
    }
  }, []);

  const fetchPedidos = async (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const pedidosData = await getPedidos(decodedToken);
      setPedidos(pedidosData);
    } catch (error) {
      alert("Faça login novamente:", error);
    }
  };

  const header = (
    <div className="flex justify-content-between align-items-center">
      <h3 className="m-3">Pedidos</h3>
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <InputText
          type="text"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pesquisar"
        />
      </span>
    </div>
  );

  return (
    <>
      <Banner />
      <Header />
      <Sidemenu />
      <div className="container-pedidos">
        {isLoggedIn ? (
          <div className="card">
            <DataTable 
              value={pedidos} 
              showGridlines 
              tableStyle={{ minWidth: '50rem' }}
              paginator={pedidos.length > 10}  
              rows={rows}                      
              rowsPerPageOptions={[5, 10, 20]}  
              sortMode="multiple"
              globalFilter={globalFilter}  
              header={header}               
              emptyMessage="Nenhum pedido encontrado."
            >
              <Column field="" header="Produto" body={() => "Derma Lift"}  />
              <Column field="quantidade" header="Quantidade"  />
              <Column field="valor_pago" header="Preço"  />
              <Column field="metodo_pagamento" header="Forma Pagamento" />
              <Column field="status" header="Status Pagamento" />
            </DataTable>
          </div>
        ) : (
          <h2 className='textoLogin'>Faça o login para visualizar seus pedidos.</h2>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Pedidos;

