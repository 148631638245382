import React from 'react';
import './header.css';
import logo from '../../img/logo.png';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className="header-component-home">
      <div className='logo-header'>
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="btns">
      </div>
    </div>
  );
}

export default Header;