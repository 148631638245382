import React, { useState, useEffect } from 'react';
import './sidemenu.css';
import { Link, useNavigate } from 'react-router-dom';
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlineShoppingCart } from "react-icons/md";
import { PiHandSoap } from "react-icons/pi";
import { MdOutlinePeople } from "react-icons/md";
import { VscCallOutgoing } from "react-icons/vsc";
import { FaList, FaArrowLeft } from "react-icons/fa6";
import { CiLogout } from "react-icons/ci";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const email = payload.email;
        const adminStatus = payload.isAdmin;

        if (email) {
          setUserEmail(email);
        }
        if (adminStatus) {
          setIsAdmin(adminStatus);
        }
      } catch (error) {
        console.error("Erro ao decodificar o token JWT", error);
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUserEmail(null);
    navigate('/');
  };

  const handleMinhasComprasClick = (e) => {
    if (!userEmail) {
      e.preventDefault();
      navigate('/login');
    }
    else {
      navigate('/pedidos');
    }
  };

  const handleQuemSomosClick = (e) => {
    e.preventDefault();
    navigate('/#sobre-nos');
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="toggle-button" onClick={toggleSidebar}>
        {isOpen ? <FaArrowLeft /> : <FaList />}
      </div>
      <ul>
        {isAdmin && (
          <Link id="linkConta" to="/clientes">
            <li id="linkConta"><MdOutlinePeople />Clientes</li>
          </Link>
        )}

        {userEmail ? (
          <li id="linkConta" className='loginUser'>
            <FaRegUserCircle />
            <span>{userEmail}</span>
          </li>
        ) : (
          <Link id="linkConta" to="/login">
            <li id="linkConta">
              <FaRegUserCircle />
              Minha Conta
            </li>
          </Link>
        )}

        <Link id="linkConta" to="/pedidos" onClick={handleMinhasComprasClick}>
          <li id="linkConta"><MdOutlineShoppingCart />Minhas Compras</li>
        </Link>

        <Link id="linkConta" to="/compras">
          <li id="linkConta"><PiHandSoap />Produtos</li>
        </Link>

        <a href="#sobre-nos" id="linkConta" onClick={handleQuemSomosClick}>
          <li id="linkConta"><MdOutlinePeople />Quem somos</li>
        </a>

        <Link id="linkConta" to="/sac">
          <li id="linkConta"><VscCallOutgoing />SAC</li>
        </Link>

        <li id="linkConta" onClick={handleLogout}>
          <CiLogout />
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
