import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './compras.css';
import produtoImg from '../../img/fotoCompra.jpeg';
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import Sidemenu from '../../components/sidemenu/sidemenu'
import Banner from '../../components/banner/banner'

function Compras() {
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const unitPrice = 69.90;

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleComprarClick = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Você precisa estar logado para acessar a página de pagamento.');
      navigate('/login');
    } else {
      navigate('/pagamento', { state: { quantity, unitPrice } });
    }
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    { question: "Está regularizada na Anvisa?", answer: "Sim, conforme o processo número 25351.376860/2024-48." },
    { question: "Foi testada em animais?", answer: "Não. A VittaLux não realiza testes em animais." },
    { question: "Qual o prazo de validade?", answer: "Dois anos, contados da data de fabricação." },
    { question: "Pode ser usada junto com outras fórmulas?", answer: "Sim. Praticamente não há restrições para uso conjunto com outros ativos." },
    { question: "Pode ser aplicada na área dos olhos?", answer: "Sim. Possui benefício secundário de redução de bolsas e linhas finas. Não aproximar muito das pálpebras para evitar contato direto com os olhos." },
    { question: "Causa acnes ou cravos?", answer: "Não. Sua fórmula não oleosa auxilia na hidratação, nutrição e possui ativos antioxidantes indicados para pele acneica." },
    { question: "Ingredientes ativos", answer: "O Derma Lift foi projetado para atender todas as necessidades que sua pele possui. Sua fórmula combina o poder rejuvenescedor do ácido hialurônico, com vitaminas antioxidantes como a vitamina E, ureia, D pantenol e Niacinamida." },
    { question: "Qual a frequência recomendada de uso?", answer: "Aplique diariamente, de manhã e à noite, após a limpeza da pele, para melhores resultados." },
    { question: "É seguro para todos os tipos de pele?", answer: "Sim, é indicado para todos os tipos de pele, inclusive peles sensíveis." },
    { question: "É necessário o uso de protetor solar junto?", answer: "Sim, o uso de protetor solar é sempre recomendado após a aplicação de qualquer produto dermatológico, especialmente durante o dia." }
  ];

  return (
    <>
      <Banner />
      <Header />
      <Sidemenu>
      </Sidemenu>
      <div className="container-compras">
        <div className="content-compras">
          <div className="produto-container-compras">
            <img src={produtoImg} alt="Produto" className="produto-imagem-compras" />

            <div className="produto-info-compras">
              <div className="valores">
                <h1>Hidratante Firmador Derma Lift</h1>
                <p className="preco-compras">R$  69,90 no Pix</p>
                <p className="preco-parcelado-compras">Parcela em até 12x no cartão de crédito </p>

                <div className="quantity-block">
                  <button className="decrement-btn" onClick={decrementQuantity}>-</button>
                  <input type="text" value={quantity} readOnly />
                  <button className="increment-btn" onClick={incrementQuantity}>+</button>
                </div>

                <button className="btnComprar-compras" onClick={handleComprarClick}>Comprar</button>
              </div>
              <div className="quemSomos">
                <p>Derma Lift Essence é um hidratante avançado que utiliza ciência dermatológica para oferecer hidratação intensiva, preenchimento de linhas finas e promover uma pele mais firme e radiante.
                </p>
                <br />
                <p>• Acalma e nutre</p>
                <p>• Regenera e preenche linhas e cicatrizes </p>
                <p>• Promove efeito lifting (sensação instantânea) </p>
                <p>• Fórmula leve e não oleosa </p>
                <p>• Previne e trata linhas de expressão</p>
              </div>

            </div>
            <div className="faq-section">
              <h2>Dúvidas Frequentes</h2>
              {faqData.map((item, index) => (
                <div key={index} className="faq-item">
                  <div className="faq-question" onClick={() => handleAccordionClick(index)}>
                    {item.question}
                  </div>
                  {activeIndex === index && (
                    <div className="faq-answer">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Compras;