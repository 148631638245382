import React, { useState } from 'react';
import './cadastro.css';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import axios from 'axios';
import Sidemenu from '../../components/sidemenu/sidemenu';
import Banner from '../../components/banner/banner';
import { useNavigate } from 'react-router-dom';
import MaskedInput from 'react-text-mask';

function Compras() {
  const URL_SERVER = process.env.REACT_APP_URL_SERVER;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    cpf: '',
    email: '',
    nome: '',
    senha: '',
    telefone: '',
    dataNascimento: '',
  });
  const [senhaErro, setSenhaErro] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.senha.length < 6) {
      setSenhaErro('A senha deve ter no mínimo 6 caracteres.');
      return;
    }

    try {
      const cadastroData = {
        ...formData,
        cpf: formData.cpf.replace(/\D/g, ''),
      };

      await axios.post(`${URL_SERVER}/users/add`, cadastroData);
      alert('Cadastro Realizado com Sucesso, efetue o Login');
      navigate('/login');
      
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert(`Erro: ${error.response.data.error}`);
      } else {
        alert('Erro ao realizar o cadastro. Tente novamente.');
      }
    }
  };

  return (
    <div className="container-cadastro">
      <Banner />
      <Sidemenu />
      <Header />

      <div className="content-cadastro">
        <div className="form-container-cadastro">
          <h2>Cadastro</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="nome">Nome:</label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={formData.nome}
              placeholder="Digite seu nome completo"
              required
              onChange={handleChange}
            />

            <label htmlFor="cpf">CPF:</label>
            <MaskedInput
              id="cpf"
              name="cpf"
              placeholder="999.999.999-99"
              type="text"
              value={formData.cpf}
              onChange={handleChange}
              mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
              required
            />

            <label htmlFor="telefone">Telefone:</label>
            <MaskedInput
              id="telefone"
              name="telefone"
              placeholder="(99) 99999-9999"
              type="text"
              value={formData.telefone}
              onChange={handleChange}
              mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              required
            />

            <label htmlFor="dataNascimento">Data de Nascimento:</label>
            <input
              type="date"
              id="dataNascimento"
              name="dataNascimento"
              value={formData.dataNascimento}
              required
              onChange={handleChange}
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              placeholder="Digite seu email (Ex: email@email.com)"
              required
              onChange={handleChange}
            />

            <label htmlFor="senha">Senha:</label>
            <input
              type="password"
              id="senha"
              name="senha"
              value={formData.senha}
              placeholder="Mínimo 6 caracteres"
              required
              onChange={(e) => {
                handleChange(e);
                if (e.target.value.length >= 6) {
                  setSenhaErro('');
                }
              }}
            />
            {senhaErro && <p style={{ color: 'red' }}>{senhaErro}</p>}

            <button type="submit">Cadastrar</button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Compras;
