import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Pagamento.css';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Sidemenu from '../../components/sidemenu/sidemenu';
import Banner from '../../components/banner/banner';
import { postPix, postCard, consultarFrete } from "../../api/service.ts";
import { StatusScreen, CardPayment, initMercadoPago } from '@mercadopago/sdk-react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import MaskedInput from 'react-text-mask';
import { Watch } from 'react-loader-spinner';
import { jwtDecode } from 'jwt-decode';

const MySwal = withReactContent(Swal);

const Pagamento = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const location = useLocation();
  const [idCliente, setIdCliente] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const { id } = decodedToken;
      setIdCliente(id);

      if (!location.state?.quantity) {
        navigate('/compras');
      }
    } catch (error) {
      navigate('/login');
    }

    initMercadoPago(process.env.REACT_APP_PUBLIC_KEY, { locale: 'pt-BR' });
  }, [navigate, location, token]);

  // Dados do produto
  const { quantity = 0, unitPrice = 0 } = location.state || {};
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showOrdersButton, setShowOrdersButton] = useState(false);

  // Forma de pagamento
  const [showCreditCardDetails, setShowCreditCardDetails] = useState(false);
  const [showPixDetails, setShowPixDetails] = useState(false);
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [paymentId, setPaymentId] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(false);

  // Frete
  const [freteOpcoes, setFreteOpcoes] = useState([]);
  const [selectedFrete, setSelectedFrete] = useState(null);
  const [freteConsultado, setFreteConsultado] = useState(false);
  const [cepDestino, setCepDestino] = useState("");
  const [selectedFretePrice, setSelectedFretePrice] = useState(null);
  const [complemento, setComplemento] = useState("");
  const [numeroCasa, setNumeroCasa] = useState("");
  const [rua, setRua] = useState("");
  const [tipoFrete, setSelectTipoFrete] = useState("");

  const retryGetFormData = async (maxAttempts = 3, delay = 500) => {
    let attempts = 0;
    while (attempts < maxAttempts) {
      try {
        const formData = await window.cardPaymentBrickController.getFormData();
        return formData;
      } catch (error) {
        attempts++;
        if (attempts >= maxAttempts) throw error;
        await new Promise(resolve => setTimeout(resolve, delay));
      }
    }
  };

  const handleCreditCard = async () => {
    try {

      const cardFormData = await retryGetFormData();

      if (!cardFormData || Object.values(cardFormData).some(value => !value)) {
        MySwal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Por favor, preencha todos os campos do cartão antes de prosseguir.',
        });
        return;
      }
      
      const commonData = getCommonPaymentData();
      setLoading(true);

      const response = await postCard({
        ...cardFormData,
        ...commonData,
      });

      setPaymentId(response.data.id);
      resetPaymentOptions();
      setShowOrdersButton(true);

    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Erro',
        text: `Erro ao processar o pagamento!`,
      });
    }
    finally {
      setLoading(false);
    }
  };

  const handlePostPix = async () => {

    const commonData = getCommonPaymentData();
    setLoading(true);

    const body = {
      transaction_amount: totalAmount,
      email: email,
      number: cpf.replace(/\D/g, ''),
      ...commonData,
    };

    try {
      const response = await postPix(body);
      if (response?.data?.point_of_interaction?.transaction_data?.ticket_url) {

        const ticketUrl = response.data.point_of_interaction.transaction_data.ticket_url;
        window.open(ticketUrl, '_blank');

        setEmail('');
        setCpf('');
        resetPaymentOptions();
        setShowOrdersButton(true);
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao gerar o pagamento PIX.',
        });
      }
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Erro',
        text: `Erro ao gerar o pix, entre em contato com o suporte!`,
      });
    }
    finally {
      setLoading(false);
    }
  };

  const getCommonPaymentData = () => {
    return {
      clientId: idCliente,
      quantity,
      numeroCasa,
      complemento,
      cepDestino,
      rua,
      tipoFrete,
      type: 'CPF',
    };
  };

  const handlePaymentMethodChange = (event) => {
    const method = event.target.id;
    setPaymentId(null);
    setSelectedPaymentMethod(method);
    setShowCreditCardDetails(method === 'credit-card');
    setShowPixDetails(method === 'pix');
  };

  const onError = async (error) => {
    MySwal.fire({
      icon: 'error',
      title: 'Erro',
      text: `Erro no pagamento: ${error.message}`,
    });
  };

  const resetPaymentOptions = () => {
    setSelectedPaymentMethod(null);
    setShowCreditCardDetails(false);
    setShowPixDetails(false);
  };

  const handleSubmit = async () => {
    if (selectedPaymentMethod === 'credit-card') {
      await handleCreditCard();
    } else if (selectedPaymentMethod === 'pix') {
      if (validateForm()) {
        await handlePostPix();
      }
    } else {
      MySwal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Nenhum método de pagamento selecionado.',
      });
    }
  };

  const validateForm = () => {
    let valid = true;
    let errors = [];

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      errors.push('E-mail inválido');
      valid = false;
    }

    if (cpf.length !== 14) {
      errors.push('CPF inválido');
      valid = false;
    }

    if (!valid) {
      MySwal.fire({
        icon: 'error',
        title: 'Erro de Validação',
        html: `<ul>${errors.map(error => `<li>${error}</li>`).join('')}</ul>`,
      });
    }

    return valid;
  };

  const consultarFreteHandler = async () => {
    setLoading(true);
    try {
      const body = {
        cepDestino,
        quantity,
        unitPrice
      };
      const response = await consultarFrete(body);
      setFreteOpcoes(response.data);
      setFreteConsultado(true);
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao consultar o frete.',
      });
    }
    finally {
      setLoading(false);
    }
  };

  const confirmarFreteHandler = () => {
    const requiredFields = [
      { value: selectedFrete, message: 'Por favor, selecione uma opção de frete antes de continuar.' },
      { value: numeroCasa, message: 'Por favor, preencha o número da casa antes de continuar.' },
      { value: rua, message: 'Por favor, preencha a rua antes de continuar.' }
    ];

    for (const field of requiredFields) {
      if (!field.value) {
        MySwal.fire({
          icon: 'error',
          title: 'Erro',
          text: field.message,
        });
        return;
      }
    }

    const calculatedTotal = parseFloat((quantity * unitPrice).toFixed(2)) + parseFloat(selectedFretePrice);
    setTotalAmount(parseFloat(calculatedTotal.toFixed(2)));
    setShowPaymentOptions(true);
  };

  const handleFreteChange = (event) => {
    const [id, price, name] = event.target.value.split('|');
    setSelectedFrete(id);
    setSelectedFretePrice(price);
    setSelectTipoFrete(name);
  };

  const renderPixDetails = () => (
    <div className="pix-details">
      <h2>Preencha seus dados para o pagamento:</h2>
      <div className="form-group">
        <label htmlFor="email">E-mail:</label>
        <input
          id="email"
          placeholder="exemplo@email.com"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="cpf">CPF:</label>
        <MaskedInput
          id="cpf"
          placeholder="999.999.999-99"
          type="text"
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
          mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          required
        />
      </div>
    </div>
  );

  const voltarParaCepHandler = () => {
    setFreteConsultado(false);
    setFreteOpcoes([]);
    setCepDestino("");
  };

  return (
    <>
      <Banner />
      <Header />
      <Sidemenu />
      {loading && (
        <div className="loader-container">
          <Watch
            visible={true}
            height="80"
            width="80"
            radius="48"
            color="#4fa94d"
            ariaLabel="watch-loading"
          />
        </div>
      )}
      <div className="container-pagamento">
        <div className="pagamento-content">
          {paymentId && (
            <StatusScreen
              initialization={{ paymentId }}
              onError={onError}
            />
          )}

          {!showPaymentOptions ? (
            <>
              <h1>Consulte e escolha o frete</h1>
              <div className="payment-method">
                <div className="form-group">
                  <label htmlFor="cep">CEP para envio:</label>
                  <MaskedInput
                    id="cep"
                    placeholder="00000-000"
                    type="text"
                    value={cepDestino}
                    onChange={(e) => setCepDestino(e.target.value)}
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                    required
                    disabled={freteConsultado}
                  />
                </div>

                {freteConsultado && (
                  <>
                    <div className="form-group">
                      <label htmlFor="rua">Rua:</label>
                      <input
                        id="rua"
                        placeholder="Digite o nome da rua"
                        type="text"
                        value={rua}
                        onChange={(e) => setRua(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="numero">Número Casa:</label>
                      <input
                        id="numero"
                        placeholder="000"
                        type="number"
                        value={numeroCasa}
                        onChange={(e) => setNumeroCasa(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="complemento">Complemento:</label>
                      <input
                        id="complemento"
                        placeholder="Apartamento, Bloco, etc."
                        type="text"
                        value={complemento}
                        onChange={(e) => setComplemento(e.target.value)}
                      />
                    </div>
                  </>
                )}

                {freteOpcoes.length > 0 && (
                  <div className="opcoes-frete">
                    <h2>Escolha uma opção de frete:</h2>
                    {freteOpcoes.map((opcao, index) => (
                      <div key={index}>
                        <input
                          type="radio"
                          name="frete"
                          value={`${opcao.id}|${opcao.price}|${opcao.name}`}
                          onChange={handleFreteChange}
                        />
                        <label>{`${opcao.name} - R$ ${opcao.price} - Prazo: ${opcao.delivery_time} dias`}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {freteConsultado ? (
                <>
                  <button className="submit-button" onClick={confirmarFreteHandler}>
                    <i className="fas fa-check"></i> Confirmar Frete
                  </button>
                  <button className="submit-button top-voltar" onClick={voltarParaCepHandler}>
                    <i className="fas fa-arrow-left"></i> Voltar
                  </button>
                </>
              ) : (
                <button className="submit-button" onClick={consultarFreteHandler}>
                  <i className="fas fa-truck"></i> Consultar Frete
                </button>
              )}
            </>
          ) : (
            <>
              <h1>Escolha a Forma de Pagamento</h1>
              <div className="payment-method">
                <input id="credit-card" name="payment-method" type="radio" onChange={handlePaymentMethodChange} />
                <label htmlFor="credit-card">Cartão de Crédito ou Débito</label>
                {showCreditCardDetails && (
                  <CardPayment
                    style={{ margin: '0' }}
                    initialization={{ amount: totalAmount }}
                    customization={{
                      visual: {
                        hideFormTitle: true,
                        hidePaymentButton: true,
                      },
                      paymentMethods: {
                        maxInstallments: 12,
                      }
                    }}
                    onError={onError}
                  />
                )}
              </div>

              <div className="payment-method">
                <input id="pix" name="payment-method" type="radio" onChange={handlePaymentMethodChange} />
                <label htmlFor="pix">Pix</label>
                {showPixDetails && renderPixDetails()}
              </div>

              <button className="submit-button" onClick={handleSubmit}>
                <i className="fas fa-lock"></i> Pagar
              </button>
              {showOrdersButton && (
                <button className="submit-button pedidos" onClick={() => navigate('/pedidos')}>
                  <i class="fa fa-shopping-cart"></i> Meus pedidos
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Pagamento;

