import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Index.jsx';
import Login from './Pages/Login/Login';
import Cadastro from './Pages/Cadastro/Cadastro';
import Compras from './Pages/Compras/Compras';
import Sac from './Pages/Sac/Sac';
import Pagamento from './Pages/Pagamento/Pagamento';
import Pedidos from './Pages/Pedidos/Pedidos';
import Clientes from './Pages/Clientes/Clientes';

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/cadastro' element={<Cadastro/>} />
      <Route path='/compras' element={<Compras/>} />
      <Route path='/sac' element={<Sac/>} />
      <Route path='/pagamento' element={<Pagamento/>} />
      <Route path='/pedidos' element={<Pedidos/>} />
      <Route path='/clientes' element={<Clientes/>} />
      </Routes>
    </Router>
  );
};

export default App